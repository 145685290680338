.connection-form {
  display: flex;
  flex-direction: column;
  height: 100%;
  .connection-form-body {
    flex: 1 auto;
    overflow-y: auto;
    textarea {
      min-height: 120px;
    }
    .connection-type-header {
      display: flex;
      padding-bottom: 30px;
      .connection-logo {
        float: left;
        margin-right: 20px;
        width: 50px;
        height: auto;
        align-self: flex-start;
      }
      strong {
        font-size: 18px;
        display: block;
        padding: 0 0 5px;
      }
    }
    .connection-groups-list,
    .connection-form-wrapper {
      padding: 30px 40px;

      @media (max-width: @screen-sm) {
        padding: 10px;
      }
    }
    .connection-groups-list {
      display: flex;
      flex-wrap: wrap;
      .connections-group-item {
        flex: 1 auto;
        width: 32%;
        margin-right: 10px;
        margin-bottom: 30px;
        background: #f5f7f9;
        border-radius: 6px;
        padding: 10px;

        @media (max-width: @screen-sm) {
          width: 100%;
        }

        .title-container {
          position: relative;
          display: flex;
          align-items: center;
          margin-bottom: 10px;

          svg {
            margin-left: 15px;
            margin-top: 12px;
          }

          .components-list-item-icon-background {
            position: absolute;
            opacity: 0.1;
            pointer-events: none;
            background: #3d4651;
            border-radius: 10px;
            width: 40px;
            height: 40px;
            left: 5px;
          }

          .components-list-item-icon {
            transform: scale(0.6);
            pointer-events: none;
            fill: transparent;
            stroke: #3d4651;
            stroke-width: 1;
            stroke-linecap: round;
            stroke-linejoin: round;
            transition: all 0.2s ease-in;
          }

          .lead {
            font-weight: 300;
            display: block;
            margin-bottom: 0;
            padding-left: 7px;
            font-size: 21px;
          }
        }

        .connection-item {
          cursor: pointer;
          display: flex;
          flex-direction: row;
          border-bottom: 1px solid transparent;
          align-items: center;
          background: #ffffff;
          margin: 8px 0;
          border-radius: 10px;
          transition: all 0.2s ease-in;
          height: 52px;
          padding: 5px 10px;

          &:hover {
            background: @brand-primary;

            span {
              color: #ffffff;
            }

            .designer-component-icon {
              stroke: #ffffff;
            }

            img {
              filter: none;
            }
          }

          img {
            width: 22px;
            margin-right: 5px;
          }
          span {
            font-size: 13px;
          }
        }
      }
    }
    .loader {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-20px, 0);
    }
    .connection-form-container {
      margin: 0;

      &.loading {
        opacity: 0.1;
        pointer-events: none;
      }
    }
    .connection-form-facebookadsinsights,
    .connection-form-intercom,
    .connection-form-bingads,
    .connection-form-adwords,
    .connection-form-analytics {
      .lead {
        padding: 30px 0;
      }
    }
  }
  .connection-form-footer {
    position: relative !important;
    flex: 0 auto !important;
    height: 80px !important;
    min-height: 80px !important;
    width: 100%;

    xp-submit-button {
      margin-left: 10px;
    }
  }

  .connection-form-mongo {
    .replica-row {
      margin-bottom: 5px;

      .replica-input {
        display: flex;
        align-items: center;

        .replica-xp-input {
          width: 80%;
        }

        .btn-link {
          border: none;

          .fa {
            color: @brand-primary;
          }

          &:hover {
            .fa {
              color: darken(@brand-primary, 10%);
            }
          }
        }
      }
    }
  }

  .copy-public-key-container {
    display: flex;
    margin-bottom: 10px;

    .copy-public-key-button {
      width: 40px;
      margin-left: 10px;
      border: 1px solid #cccccc;
    }
  }
}
