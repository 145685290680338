@ob-green: #13a95b;
@ob-gray: #f6f6f6;
@ob-text: #3f3f3f;
@ob-padding: 11px;
@ob-padding-out: 20px;

.onboarding-toolbar {
  background-color: #fff;
  height: 50px;
  align-items: center;
  display: flex;
  justify-content: space-between;

  @media (max-width: @screen-sm) {
    justify-content: flex-end;
  }

  .btn-name {
    margin-left: 40px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    margin-right: 10px;

    @media (max-width: @screen-sm) {
      display: none;
    }
  }

  .trial-group {
    border-radius: 16px;
    background: #f3f5f7;
    padding: 4px 4px 4px 12px;
    display: flex;
    align-items: center;

    @media (max-width: @screen-sm) {
      display: none;
    }

    .text-info {
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #000000;
      margin-right: 10px;
    }

    .btn {
      background: @brand-primary;
      color: #ffffff;
      border-radius: 16px;
      padding: 3px 10px;
      font-weight: 400;
    }
  }

  .right-container {
    display: flex;
    height: 100%;

    .feedback-button {
      margin-right: 20px;
      height: 36px;
      margin-top: 7px;
      border-radius: 5px;
      font-size: 13px;
    }
  }

  .help-button {
    cursor: pointer;
    height: 100%;
    padding: 20px;
    display: flex;
    align-items: center;
    background: transparent;
    border: none;
    border-left: 1px solid #cccccc;

    @media (max-width: @screen-sm) {
      display: none;
    }

    &:hover {
      background: #f3f5f7;
    }

    .text-info {
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: @brand-primary;
      margin-right: 10px;
    }

    .icon {
      width: 20px;
      margin-right: 20px;
    }
  }
}

.apps-toolbar {
  height: 100%;

  @media (max-width: @screen-sm) {
    display: block;
    width: fit-content;
  }
}

.apps-button {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  color: #3f3f3f;
  text-decoration: none solid rgb(63, 63, 63);
  cursor: pointer;
  pointer-events: all !important;
  height: 100%;
  padding: 20px;
  background: transparent;
  border: none;
  border-left: 1px solid #cccccc;
  margin: 0;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;

  &.hover {
    background: #f3f5f7;
  }

  &.active {
    background: #dbdcde;
  }

  .icon {
    background-image: url('../../../../assets/img/icons/etl.svg');
    width: 24px;
    height: 20px;
    margin-right: 10px;
  }

  .fa {
    margin-left: 10px;
  }
}

.mat-dropdown-menu-item.app-item {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  height: 59px;
  padding: 10px 20px;
  border-radius: 4px;
  background-color: #ffffff;
  background-size: cover;
  font-size: 14px;
  color: #3f3f3f;
  text-decoration: none solid rgb(63, 63, 63);
  line-height: 35px;
  font-weight: 400;
  width: 220px;

  .icon {
    margin-right: 10px;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-size: 20px 20px;
    display: inline-block;
  }

  &.elt_cdc {
    .icon {
      background-image: url('../../../../assets/img/icons/elt_cdc.svg');
    }
  }

  &.api-generation {
    .icon {
      background-image: url('../../../../assets/img/icons/api_generation.svg');
    }
  }

  &.dwh-insights {
    .icon {
      background-image: url('../../../../assets/img/icons/dwh.svg');
    }
  }
}

.mat-mdc-menu-panel.apps-dropdown-menu {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: #f4f4f4;
  background-size: cover;
  margin-top: 10px;
}

.integrateio-notification {
  width: 450px;
  height: 400px;
  background: #ffffff;
  box-shadow:
    0 8px 8px -4px rgba(0, 0, 0, 0.03),
    0px 20px 24px -4px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;

  .dialog-close {
    top: 30px !important;
  }

  .title {
    font-weight: 400;
    font-size: 18px;
    line-height: 130%;
    text-align: center;
    color: #2d3e4a;
    width: 180px;
    margin-top: 0;

    .link {
      color: @brand-primary;
      cursor: pointer;
    }
  }

  .description {
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    text-align: center;
    color: #2d3e4a;
    margin-top: 10px;
    width: 370px;

    strong {
      font-weight: 600;
    }
  }

  .image {
    height: 150px;
  }

  .migrate-button {
    margin-top: 20px;
    width: 220px;
    height: 45px;
    background: linear-gradient(264.29deg, @brand-primary 0%, #4a98f4 100%);
    border-radius: 8px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: 300;

    .icon {
      position: absolute;
      right: 20px;
      height: 25px;
    }
  }

  @-webkit-keyframes ticker {
    0% {
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    position: absolute;
    top: 0;
    width: 100%;
    overflow: hidden;
    height: 26px;
    padding-left: 100%;
    box-sizing: content-box;
    background: #081d34;

    .ticker {
      display: inline-block;
      height: 26px;
      line-height: 26px;
      white-space: nowrap;
      box-sizing: content-box;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      animation-name: ticker;
      animation-duration: 30s;
      width: 100%;
      margin-left: -380px;

      &__item {
        padding: 0 2rem;
        font-weight: 600;
        font-size: 13px;
        text-align: center;
        color: #ffffff;
        opacity: 0.4;
        line-height: 26px;
        display: inline-block;
      }
    }
  }
}
